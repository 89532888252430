import React, { Component } from 'react';
import firebase from 'firebase';
import CommentForm from './Commentform'
import CommentList from './Commentlist'


// const config = {
// 	apiKey: "AIzaSyDKIBKxsDJ6e3XjLzBgRA_OEo3d16o8RRQ",
//   authDomain: "finalproject-cd8aa.firebaseapp.com",
//   databaseURL: "https://finalproject-cd8aa.firebaseio.com",
//   projectId: "finalproject-cd8aa",
//   storageBucket: "",
//   messagingSenderId: "248908905974"
// };
// firebase.initializeApp(config);

// const Comment = ({username, comment, time}) => (
//   <div className="comment">
//     <h4>{username} says</h4>
//     <p className="timestamp">{time}</p>
//     <p>{comment}</p>
//   </div>
// );

class ContactForm extends Component {
	render() {
    return (
			<div>
			<CommentForm />
			<CommentList />
			</div>


		 )
	}
}



// class CommentForm extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       username: '',
//       comment: ''
//     };
//     this.handleSubmit = this.handleSubmit.bind(this);
//     this.handleChange = this.handleChange.bind(this);
//   }
//   formatTime() {
//     const options = {
//       month: '2-digit',
//       day: '2-digit',
//       year: '2-digit',
//       hour: '2-digit',
//       minute:'2-digit'
//     };
//     let now = new Date().toLocaleString('en-US', options);
//     return now;
//   }
//   escapeHTML(html) { // [1]
//     const div = document.createElement('div');
//     div.textContent = html;
//     return div.innerHTML;
//   }
//   handleSubmit(e) {
//     e.preventDefault();
//
//     const user = {
//       username: this.escapeHTML(this.state.username),
//       comment: this.escapeHTML(this.state.comment),
//       time: this.formatTime(),
//     }
//
//     const db = firebase.database().ref('comments');
//     db.push(user);
//
//     this.setState({
//       username: '',
//       comment: ''
//     })
//   }
//   handleChange(e) {
//     this.setState({
//       [e.target.name]: e.target.value
//     })
//   }
//   render() {
//     return (
//       <div className="comments-form">
//         <form onSubmit={this.handleSubmit}>
//           <ul>
//             <li>
//               <input
//                 name="username"
//                 type="text"
//                 placeholder="Name"
//                 value={this.state.username}
//                 onChange={this.handleChange}
//                 required
//                />
//             </li>
//             <li>
//               <textarea
//                 name="comment"
//                 placeholder="Comment"
//                 value={this.state.comment}
//                 onChange={this.handleChange}
//                 required
//               ></textarea>
//             </li>
//             <li><input type="submit" value="Post" /></li>
//           </ul>
//         </form>
//       </div>
//     )
//   }
// }





// const Comment = ({username, comment, time}) => (
//   <div className="comment">
//     <h4>{username} says</h4>
//     <p className="timestamp">{time}</p>
//     <p>{comment}</p>
//   </div>
// );
export default ContactForm;


// const mountNode = document.getElementById("app");
// ReactDOM.render(<FormApp />, mountNode);
