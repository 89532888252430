import React, { Component } from 'react';
import logo from './aquaponics.jpg';
import Fade from 'react-reveal/Fade';
import Firebase from './Firebase';
//import CommentForm from './Commentform';
import ContactForm from './Commentfirebase';

//import Formapp from './Commentfirebase'

class Contact extends Component {
	render() {
    return (

      <header className="masthead">
       <div className="container h-100" >
  		    <div className="row h-100  justify-content-center text-center">
          <h1 className="text-white font-weight-bold">Leave me some feedback</h1>

          <ContactForm />

        </div>
        </div>
        </header>
    )
	}
}

export default Contact;
