import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from './aquaponics.jpg';
import tank from './images/tank.jpg';
import sump from './images/sump.jpg';
import solids from './images/solid.jpg';
import kidsloving from './images/kids-loving.jpg';
import lettuce from './images/lettuce.jpg';
import tomatoe from './images/tomatoe.jpg';
import growbeds from './images/growbeds1.jpg';
import growbed from './images/growbeds.jpg';
import fruit from './images/fruits2.jpg';
import system from './images/system.jpg';
import topology from './images/SystemTopology.jpg';
import json from './images/SoftwareTopology.jpg';
import Fade from 'react-reveal/Fade';
import Firebase from './Firebase';
import './css/creative.css';
//import './css/creative.min.css';


class About extends Component {
	render() {
    return (
			<div>
		<header className="mastheadabout">
     <div className="container h-100" >
		 <div className="row h-100 align-items-center justify-content-center text-center">

    <h1 className="text-white font-weight-bold">What is Aquaponics?</h1>


		<Fade left>
    	<img src={logo} className="rounded" alt="Aquaponics System" height="300" width="800" />
		</Fade>


    <p> </p>
    <p class="text-white mb-4">Aquaponics is a form of agriculture that combines raising fish in tanks (recirculating aquaculture)
    with soilless plant culture (hydroponics).  In aquaponics, the nutrient-rich water from raising fish
    provides a natural fertilizer for the plants and the plants help to purify the water for the fish.
    Aquaponics can be used to sustainably raise fresh fish and vegetables for a family, to feed a village or
    to generate profit in a commercial farming venture, year ‘round, in any climate.</p>
		<a className="btn btn-primary btn-xl js-scroll-trigger" href="#flood">How Does It Work?</a>
					</div>
  		</div>
		</header>


		<section class="page-section" id="flood">
			<div class="container">
				<h2 class="text-center mt-0">Flood & Drain System</h2>
				<hr class="divider my-4" />

				<img src={system} className="rounded img-thumbnail" alt="Aquaponics System" height="500" width="800" />

				<div class="row">
					<div class="col-lg-3 col-md-6 text-center">
						<div class="mt-5">
							<i class="fas fa-4x fa-gem text-primary mb-4"></i>
							<img src={tank} className="rounded img-thumbnail" alt="Fish Tank" height="200" width="300" />
							<h3 class="h4 mb-2">Fish Tank</h3>
							<p class="text-muted mb-0">Where your fish lives</p>

						</div>
					</div>
					<div class="col-lg-3 col-md-6 text-center">
						<div class="mt-5">
							<i class="fas fa-4x fa-laptop-code text-primary mb-4"></i>
							<img src={solids} className="rounded img-thumbnail" alt="Solid Filter" height="200" width="300" />
							<h3 class="h4 mb-2">Solid Filter</h3>
							<p class="text-muted mb-0">Where your fish waste are collected</p>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 text-center">
						<div class="mt-5">
							<i class="fas fa-4x fa-globe text-primary mb-4"></i>
							<img src={sump} className="rounded img-thumbnail" alt="Fish Tank" height="200" width="300" />
							<h3 class="h4 mb-2">Sump Tank</h3>
							<p class="text-muted mb-0">Where water is pumped out to the fish tank and grow beds</p>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 text-center">
						<div class="mt-5">
							<i class="fas fa-4x fa-heart text-primary mb-4"></i>
							<img src={growbeds} className="rounded img-thumbnail" alt="Fish Tank" height="200" width="300" />
							<h3 class="h4 mb-2">Grow Beds</h3>
							<p class="text-muted mb-0">Where your plants are grown.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

					<section class="page-section bg-primary" id="more">
						<div class="container h-100">
							<div class="row justify-content-center">
								<div class="col-lg-8 text-center">
									<h2 class="text-white mt-0">System API Topology </h2>
									<img src={topology} className="rounded" alt="Aquaponics Topology" height="600" width="800" />
									<hr class="divider light my-4"/>
									<p class="text-white mb-4 ">Data is collected through the sensors and sent to the Microcontroller which in turns sends to the API for logging and visualization.</p>
									<a class="btn btn-light btn-xl js-scroll-trigger" href="#json">JSON!</a>
								</div	>
							</div>
						</div>
					</section>


					<section class="page-section" id="json">
				    <div class="container">
				      <h2 class="text-center mt-0">JSON Data</h2>
				      <hr class="divider my-4" />

							<img src={json} className="rounded " alt="Aquaponics Topology" height="600" width="800" />


				    </div>
				  </section>

					<header className="masthead">
			     <div className="container h-100" >
					 <div className="row h-100 align-items-center justify-content-center text-center">

			    <h1 className="text-white font-weight-bold">The Kids Love It!!!</h1>


					<Fade left>
			    	<img src={kidsloving} className="rounded" alt="Aquaponics System" height="600" width="800" />
					</Fade>


			    <p> </p>
					<div class="row">
						<div class="col-lg-3 col-md-6 text-center">
							<div class="mt-5">
								<i class="fas fa-4x fa-gem text-primary mb-4"></i>
								<img src={lettuce} className="rounded img-thumbnail" alt="Fish Tank" height="200" width="300" />


							</div>
						</div>
						<div class="col-lg-3 col-md-6 text-center">
							<div class="mt-5">
								<i class="fas fa-4x fa-laptop-code text-primary mb-4"></i>
								<img src={tomatoe} className="rounded img-thumbnail" alt="Fish Tank" height="200" width="300" />
							</div>
						</div>
						<div class="col-lg-3 col-md-6 text-center">
							<div class="mt-5">
								<i class="fas fa-4x fa-globe text-primary mb-4"></i>
								<img src={fruit} className="rounded img-thumbnail" alt="Fish Tank" height="200" width="300" />

							</div>
						</div>
						<div class="col-lg-3 col-md-6 text-center">
							<div class="mt-5">
								<i class="fas fa-4x fa-heart text-primary mb-4"></i>
								<img src={growbed} className="rounded img-thumbnail" alt="Fish Tank" height="200" width="300" />

							</div>
						</div>
					</div>

						</div>
			  		</div>
					</header>


</div>

    )
	}
}

export default About;
