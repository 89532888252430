// src/Home.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withAuth } from '@okta/okta-react';
import cycle from './images/cycle.png';
import talapia from './images/talapia.jpg';
import plants from './images/plants2.jpg';

export default withAuth(class Home extends Component {
state = { authenticated: null };

   checkAuthentication = async() => {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
    }
  }

  async componentDidMount() {
    this.checkAuthentication();
  }

  async componentDidUpdate() {
    this.checkAuthentication();
  }

  login = async() => {
    this.props.auth.login('/');
  }

   logout = async() => {
    this.props.auth.logout('/');
  }

  render() {
    if (this.state.authenticated === null) return null;

    const mainContent = this.state.authenticated ? (



      <div>
      <p className="lead text-center">You have entered the secret portal. <Link to="/staff">Click here</Link> to return.</p>
      <button className="btn btn-light btn-lg " onClick={this.logout}>Logout</button>
      </div>

    ) : (
      <header className="mastheadhome">
            <div className="container h-100" >
            <div className="row h-100 align-items-center justify-content-center text-center">
            <div >
            <button className="btn btn-info btn-lg" onClick={this.login}>Login</button>
            <p className="lead text-white ">Here you will find <Link to="/live">live data</Link> and <Link to="/staff">video feed</Link> of my aquaponics system.</p>
            <h2 className="display-5 text-white">AQUA</h2>

            <figure class="figure">
            <img src={talapia} className="img-thumbnail" alt="Fish" height="300" width="400" />
            <figcaption className=" text-white">Aquaculture - Raising fish in a controlled environment</figcaption>
            </figure>

            <h2 className="display-5 text-white">+</h2>
            <h2 className="display-5 text-white">PONICS</h2>

            <figure class="figure">
            <img src={plants} className="img-thumbnail" alt="Ponics" height="300" width="400" />
            <figcaption className="text-white">Ponics - Latin "to work". Growing in soil-less media.</figcaption>
            </figure>

            <h2 class="text-white mt-0">Fish eat and produce ammonia</h2>
            <hr class="divider dark my-4" />
            <h2 class="text-white mt-0">Beneficial bateria convert ammonia into nutrients</h2>
            <hr class="divider dark my-4" />
            <h2 class="text-white mt-0">Plants absorb the nutrients</h2>
            <hr class="divider dark my-4" />
            <h2 class="text-white mt-0">Water is continuously recirculated through the system </h2>


      </div>
      </div>
      </div>
      </header>

    );


    return (
      <div>
        <h1 className=" p-3 mb-2 bg-info text-white text-center"> Welcome to Bryan's Aquaponics Portal</h1>

        {mainContent}

      </div>
    );
  }
});
