import React, { Component } from 'react';
import firebase from 'firebase';
import './contact.css'


const config = {
	apiKey: "AIzaSyDKIBKxsDJ6e3XjLzBgRA_OEo3d16o8RRQ",
  authDomain: "finalproject-cd8aa.firebaseapp.com",
  databaseURL: "https://finalproject-cd8aa.firebaseio.com",
  projectId: "finalproject-cd8aa",
  storageBucket: "",
  messagingSenderId: "248908905974"
};

firebase.initializeApp(config);

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      comment: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  formatTime() {
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute:'2-digit'
    };
    let now = new Date().toLocaleString('en-US', options);
    return now;
  }
  escapeHTML(html) { // [1]
    const div = document.createElement('div');
    div.textContent = html;
    return div.innerHTML;
  }
  handleSubmit(e) {
    e.preventDefault();

    const user = {
      username: this.escapeHTML(this.state.username),
      comment: this.escapeHTML(this.state.comment),
      time: this.formatTime(),
    }

    const db = firebase.database().ref('comments');
    db.push(user);

    this.setState({
      username: '',
      comment: ''
    })
  }
  deleteHandler(i, e) {
       e.preventDefault();
       this.props.onDelete(this.props.blogPosts[i].id);
   };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  render() {

    const formStyle = {
      borderBottom:"1px solid #cc",
      ul: {
        listStyle: "none",
        marginleft: "-40px"
      },
      li: {
        marginbottom:"15px",
        border:"0",
        borderRadius: "30px",
        background: "#448ef6",
        color: "#fff",
        fontFamily: "'Rubik', sans-serif",
        fontWeight: "bold",
        fontSize: "0.9em",
        textTransform: "uppercase",
        marginBottom: "20px"
      }
    };


    return (
      <div style={formStyle}>
        <form onSubmit={this.handleSubmit}>
          <ul className="list-group" style={formStyle}>
            <li className="list-group-item" style={formStyle}>
              <input
                name="username"
                type="text"
                placeholder="Name"
                value={this.state.username}
                onChange={this.handleChange}
                required
               />
            </li>
            <li class="list-group-item">
              <textarea
                name="comment"
                placeholder="Comment"
                value={this.state.comment}
                onChange={this.handleChange}
                required
              ></textarea>
            </li>
            <li class="list-group-item"><input type="submit" value="Post" /></li>
          </ul>
        </form>
      </div>
    )
  }
}


export default CommentForm;
