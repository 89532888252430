import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import firebase from 'firebase';
import './contact.css'
import './css/creative.css';


const config = {
	apiKey: "AIzaSyDKIBKxsDJ6e3XjLzBgRA_OEo3d16o8RRQ",
  authDomain: "finalproject-cd8aa.firebaseapp.com",
  databaseURL: "https://finalproject-cd8aa.firebaseio.com",
  projectId: "finalproject-cd8aa",
  storageBucket: "",
  messagingSenderId: "248908905974"
};


class CommentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: []
    }
  }
  componentWillMount() { // [2]
    const db = firebase.database().ref('comments');
    const MAX_COUNT = 9;
    db.on('value', (snapshot) => {
      if (snapshot.numChildren() > MAX_COUNT) {
        let childCount = 0;
        let updates = {};
        snapshot.forEach((child) => {
          if (++childCount < snapshot.numChildren() - MAX_COUNT) {
            updates[child.key] = null;
          }
        });
        db.update(updates);
      }
    });
  }

  //Create Data Comments
  componentDidMount() {
    const db = firebase.database().ref('comments');

    db.on('value', (snapshot) => {
      const comments = snapshot.val();
      const arr = [];
      for(const comment in comments) {
        console.log(comment);
        arr.push({
          username: comments[comment].username,
          comment: comments[comment].comment,
          time: comments[comment].time,
          key: comment,
          newVal: comment
        })
      };

      this.setState({
        comments: arr.reverse()
      });
    })
  }

  updateItem = (e,updateKey) => {
    //console.log(updateKey);

    let newInfo = {
      comment: this.state.newVal
    }

    console.log("newVal: " + this.state.newVal);
    firebase.database().ref('comments/'+ updateKey).update(newInfo);


  }


  removeItem (e, removeKey) {
      console.log(removeKey);
      firebase.database().ref('comments/'+ removeKey).remove();
  }

  render() {
    const commentStyle = {
      width: "500px",
      background: "#fff",
      padding: "20px 30px",
      borderRadius: "10px"
    };

    return (
      <div style={commentStyle}>
        {
          this.state.comments.map(comment => (
            <Comment
              username={comment.username}
              comment={comment.comment}
              time={comment.time}
              removeKey={comment.key}
              updateKey={comment.key}
              removeItem={this.removeItem}
              updateItem={this.updateItem}
            />
          ))
        }
      </div>
    )
  }
}

const Comment = ({username, comment, time, removeItem, removeKey, updateKey, updateItem}) => (
  <div className="comment">
    <h4>{username} says</h4>
    <button id={removeKey} onClick={e=>removeItem(e,removeKey)} className="btn btn-danger float-right"><i className="fa fa-trash"></i></button>


    <p>{comment}</p>
    <p className="timestamp">{time}</p>

  </div>
);



export default CommentList;
