import React, { Component} from 'react';
import security from './images/Camera1.jpg';

class Staff extends Component {
state = {
  currentUserName: '',
  currentUserEmail:''
}

componentDidMount() {
  const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
  this.setState({
    currentUserEmail: idToken.idToken.claims.email,
    currentUserName: idToken.idToken.claims.name
  });
}

render() {
  const { currentUserEmail, currentUserName} = this.state;

  return (

    <header className="mastheadstaff">
     <div className="container h-100" >


    <h1 className="text-white text-center">Welcome { currentUserName}</h1>
    <p className="text-white text-center">Email: {currentUserEmail}</p>
    <p className="text-white text-center">You have reached the secure portal</p>

    <img src={security} className="rounded text-center" alt="Aquaponics System" height="550" width="900" />

</div>
</header>

  )
}

}

export default Staff;
